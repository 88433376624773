<!-- eslint-disable max-len -->
<template>
  <div class="member_show">
    <div class="show_title">
      <img src="img/account/icon_title_2.svg" alt="">
      <h4 class="txt-bold">修改密碼</h4>
    </div>

    <form>
      <div class="show_inputbox">

        <div class="input_item">
          <p class="mg14">舊密碼</p>
          <div class="fill_in">
            <div class="col60">
              <input
                type="password"
                v-model="passwordData.oldPassword"
                @keyup="passwordData.oldPassword=passwordData.oldPassword.replace(/[\u4e00-\u9fa5]/g,'')"
                placeholder="請輸入您的舊密碼">
            </div>
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">新密碼</p>
          <div class="fill_in">
            <div class="col60">
              <input
                type="password"
                v-model="passwordData.newPassword"
                @keyup="passwordData.newPassword=passwordData.newPassword.replace(/[\u4e00-\u9fa5]/g,'')"
                placeholder="請輸入英數混合8位數以上">
            </div>
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">確認密碼</p>
          <div class="fill_in">
            <div class="col60">
              <input
                type="password"
                v-model="passwordData.confirmPassword"
                @keyup="passwordData.confirmPassword=passwordData.confirmPassword.replace(/[\u4e00-\u9fa5]/g,'')"
                placeholder="請再次輸入新密碼">
            </div>
          </div>
        </div>

        <div class="btn_item">
          <button
            type="button"
            @click="sendData();"
            class="btn btn-blue_gradient">
              <p>修改密碼</p>
          </button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import requestApi from '@/lib/http';
import { checkStorage } from '@/utils/timerStorage';

export default {
  name: 'MemberModifyPassword',
  data() {
    return {
      passwordData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    ...mapActions({
      actionLogout: 'logout',
    }),
    logout() {
      this.actionLogout();
      this.ifToken = checkStorage('token');
      this.$router.go(0);
      // this.$router.push({ name: 'Index' });
    },
    async sendData() {
      if (!this.passwordData.oldPassword) {
        this.$customSWAL({
          icon: 'error',
          title: '請填寫舊密碼',
        });
      } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.passwordData.newPassword)) {
        this.$customSWAL({
          icon: 'error',
          title: '密碼須超過8個字元，包含英數字',
        });
      } else if (!this.passwordData.newPassword || !this.passwordData.confirmPassword) {
        this.$customSWAL({
          icon: 'error',
          title: '請填寫與確認新密碼',
        });
      } else if (this.passwordData.newPassword !== this.passwordData.confirmPassword) {
        this.$customSWAL({
          icon: 'error',
          title: '新密碼與確認密碼不符',
        });
      } else {
        const { status, message } = await requestApi('member.resetPassword', {
          old_pwd: this.passwordData.oldPassword,
          new_pwd: this.passwordData.newPassword,
        });

        if (status) {
          this.$customSWAL({
            title: message,
            thenFunc: () => {
              this.logout();
            },
          });
        } else {
          this.$customSWAL({
            icon: 'error',
            title: message,
          });
        }
      }
    },
  },
};
</script>
